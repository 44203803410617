.scrollbar-hide {
  /* IE and Edge */
  -ms-overflow-style: none;

  /* firefox */
  scrollbar-width: none;

  /* safari and chrome */
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.chat svg {
  display: block;
  vertical-align: middle;
}

.chat {
  position: fixed;
  z-index: 10000;
  font-size: 16px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.chat.chat__display-none {
  width: 0;
  height: 0;
}
.chat__button {
  position: fixed;
  right: 1em;
  bottom: 1em;
  padding: 0.6em;
  transition-property: opacity;
  color: #ffffff;
  font-size: 1.5em;
  line-height: 2em;
  border-radius: 1em;
  box-shadow: 0 4px 6px -1px #17216bb1, 0 2px 4px -2px #17216bb1;
  background: #17216b;
  cursor: pointer;
  border: none;
}
.chat__button:hover {
  opacity: 0.7;
}
.chat__dialog {
  display: flex;
  position: fixed;
  top: 0;
  right: 20px;
  background-color: white;
  padding: 1.5em;
  flex-direction: column;
  width: 100%;
  max-width: 25em;
  height: 70vh;
  gap: 0.5em;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  background-color: #f8fafc;
  border-radius: 10px;
  transform: translateY(25vh);
}
.chat__close-btn {
  border: none;
  appearance: none;
  background: none;
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
  color: #a7aeb8;
}

.chat__title {
  margin-bottom: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
}
.chat__messages {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  flex: 1 1 0%;
  row-gap: 5px;
}
.chat__message {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 0.5em;
  font-size: 0.875em;
  line-height: 1.25em;
  border-radius: 1.5em;
  box-shadow: 0 10px 15px -3px #f1f5f9, 0 4px 6px -4px #f1f5f9;
  word-break: break-all;
}
.chat__message span {
  position: absolute;
  bottom: -50px;
  color: black;
}
.chatname {
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  margin-right: 5px;
}
.chatname_name_out {
  text-align: right;
}

.chat__message_in {
  background-color: #ffffff;
  color: #334155;
  text-align: left;
  align-self: flex-start;
  border-bottom-left-radius: 0;
}
.chat__message_out {
  color: #ffffff;
  text-align: right;
  align-self: flex-end;
  border-bottom-right-radius: 0;
  background: #17216b;
}

.chat__form {
  display: flex;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top-width: 2px;
  border-top-color: rgb(241 245 249);
  border-top-style: solid;
}
.chat__input {
  border: none;
  appearance: none;
  display: block;
  color: rgb(51 65 85);
  background-color: transparent;
  flex: 1 1 0%;
  font-size: 1.1em;
  outline: 0;
  resize: none;
}
/* WebKit, Edge */
.chat__input::-webkit-input-placeholder {
  color: #cbd5e1;
}
/* Firefox 4-18 */
.chat__input:-moz-placeholder {
  color: #cbd5e1;
  opacity: 1;
}
/* Firefox 19+ */
.chat__input::-moz-placeholder {
  color: #cbd5e1;
  opacity: 1;
}
/* IE 10-11 */
.chat__input:-ms-input-placeholder {
  color: #cbd5e1;
}
/* Edge */
.chat__input::-ms-input-placeholder {
  color: #cbd5e1;
}
/* MODERN BROWSER */
.chat__input::placeholder {
  color: #cbd5e1;
}
.chat__send-button {
  appearance: none;
  border: none;
  font-size: 1em;
  line-height: 2em;
  color: #17216b;
  background-color: transparent;
  cursor: pointer;
}

.chat__notauth {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #17216b;
  text-transform: uppercase;
}

.animated-spinner {
  width: 25px;
  height: 25px;
  display: inline;
  animation: spin 1s linear infinite;
}
.observer {
  background: transparent;
  width: 100%;
  height: 15px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lds-loader {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.lds-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 4px solid #c0c6cd;
  border-radius: 50%;
  animation: lds-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #c0c6cd transparent transparent transparent;
}
.lds-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 640px) {
  .chat__dialog {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(0);
    height: auto;
  }
}
