.shop-name{
  color: #34a6ff;
  font-size: 1.6rem;
}
.method-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.payment-option {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  align-items: flex-start;
}
.method-header-active {
  font-size: 2rem;
  font-weight: 600;
  background-color: #cddae4;
  padding: 10px;
  border: 1px solid #ebedf2;
  border-radius: 8px;
  margin-right: 20px;
  text-align: center;
}

.method-header {
  font-size: 2rem;
  font-weight: 600;
  background-color: #f0f4f7;
  padding: 10px;
  border: 1px solid #ebedf2;
  border-radius: 8px;
  margin-right: 20px;
  text-align: center;
}
.payment-body {
  width: 100%;
  border-top: 2px solid #ebedf2;
  margin: 10px 0;
  padding: 10px 0;
}
.instant-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.submit-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 3rem;
}

.order--submit {
  height: 100%;
  width: 150px;
  padding: 1.5rem 0;
  border: none;
  border-radius: 8px;
  background-color: #17216b;
  color: #fff;
  cursor: pointer;
}


