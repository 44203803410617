.filterBy__category {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 5;
}
.filterBy__category-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 10px;
}
.filterBy__category-btn,
.filterBy__category-btn svg {
  color: #17216b;
  fill: #17216b;
}
.filter__category-modal {
  position: fixed;
  background: #fff;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  top: 0;
}
.filter__category-modal::-webkit-scrollbar {
  display: none;
}

.category__modal-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  position: relative;
  width: 100%;
}
.category__modal-title h1 {
  font-size: 16px;
  font-weight: 600;
}
.category__modal-close {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #17216b;
  background: #fff;
  font-weight: 600;
  cursor: pointer;
}

.category__modalheaders {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.category__modalheaders .category__modalheaders-item {
  list-style: none;
  height: 50px;
  display: flex;

  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  cursor: pointer;
  color: #858585;
}

.category__modalheaders .category__modalheaders-item svg {
  transform: rotate(275deg);
  fill: #858585;
}

.category__modalheaders .category__modalheaders-item.active {
  background: rgba(211, 211, 211, 0.3);
}

.category__modalheaders-item:hover {
  background: rgba(211, 211, 211, 0.3);
}

.category__modalitems {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.category__modalitems-item {
  list-style: none;
  height: 50px;
  display: flex;
  color: #858585;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  cursor: pointer;
}

.category__modalitems-item:hover {
  background: rgba(211, 211, 211, 0.3);
}
.categoryfilter__divider {
  width: 90%;
  height: 2px;
  background: rgba(211, 211, 211, 0.5);
  margin: 10px 0;
}

@media screen and (min-width: 760px) {
  .filterBy__category {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    width: 100vw;
  }
  .filterBy__category-btn {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    width: 90px;
    height: 90px;
    margin: 0 15px;
    background: #17216b;
    border-radius: 25px;
    padding: 20px;
    cursor: pointer;
    border-bottom-right-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    z-index: 9999;
  }
  .filterBy__category-btn,
  .filterBy__category-btn svg {
    color: #fff;
    fill: #fff;
    font-size: 16px;
  }
  .filterBy__category-btn svg {
    width: 30px;
    height: 40px;
  }
  .filter__category-modal {
    position: fixed;
    width: 400px;
    height: 600px;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    z-index: 999999;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }
}
