.shop-products__list {
  list-style: none;
}

.shop-products__list .product-card-list {
  box-shadow: none;
  border: none;
}

.show-filter__item.active {
  color: #17216b;
}