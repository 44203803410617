.hero__card:nth-child(3) img {
  width: auto;
  height: 100%;
  bottom: -1rem;
  transform: rotateY(180deg);
}
.hero__card:nth-child(3):hover img {
  transform: rotateY(180deg) scale(1.2);
}

.hero-show_more-btn {
  font-size: 16px;
  /* letter-spacing: 1px; */
  padding: 10px 24px 8px;
  border: 1px solid #fff;
  cursor: pointer;
  position: relative;
  z-index: 1;
  color: #17216b;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
}

.hero-show_more-btn:after {
  content: "";
  background-color: rgba(255, 255, 255, 0.8);
  z-index: -1;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  transition: 0.2s;
  border-radius: 6px;
}

.hero-show_more-btn:hover:after {
  background-color: #fff;
}
