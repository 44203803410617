.cards-container {
  width: 100%;
  background-color: #fff;
  border: 1px solid #ebedf2;
  border-radius: 8px;
  box-shadow: 0 14px 20px rgba(54, 56, 77, 0.146279);
  margin-bottom: 10rem;
}
.cards-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.save-container {
  display: flex;
  margin: 0;
  padding: 0;
  height: 300px;
  width: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.card-item {
  width: 275px;
  height: 170px;
  margin: 20px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  background-color: #17216b;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.active-card {
  -webkit-box-shadow: 0px 0px 5px 6px rgba(23, 33, 107, 0.75);
  -moz-box-shadow: 0px 0px 5px 6px rgba(23, 33, 107, 0.75);
  box-shadow: 0px 0px 5px 6px rgba(23, 33, 107, 0.75);
}

.save-card-item {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.save-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 80%;
  border-radius: 8px;
  font-weight: 500;
  background-color: #f0f4f7;
  color: black;
}

.conf-card--id {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
.conf-card--maskNum {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}
.conf-card--code {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
}

.card-top {
  width: 100%;
  font-size: 2rem;
  padding: 0.5em;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-top--balance {
  color: #17216b;
  font-size: 1em;
  font-weight: 500;
}
.card-center {
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-bottom {
  width: 100%;
  padding: 0.5em;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
}
.phone-code {
  background-color: white;
  width: 20%;
  height: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.phone-input {
  width: 75%;
  height: 100%;
  font-size: 1.5rem;
}

.payment__method label {
  padding: 8px 15px;
  color: #17216b;
  background-color: transparent;
  border: 1px solid #17216b;
  cursor: pointer;
  font-size: 20px;
}

.payment__method label.active {
  background-color: #17216b;
  color: #fff;
}

.payment__method label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.payment__method label:nth-of-type(2) {
  border-radius: 0 4px 4px 0;
}

.payment__method input {
  display: none;
}

.save-card--submit {
  width: 100%;
  padding: 1.5rem 0;
  border: none;
  border-radius: 8px;
  background-color: #17216b;
  color: #fff;
  cursor: pointer;
}

.save-card--submit.disabled {
  background-color: #17216b90;
  cursor: not-allowed;
}
.save-card--input {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  height: 70%;
}
.save-card--phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  height: 20%;
}
.save-card--input .card-number {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.save-card--input .card-exp {
  width: 70%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-number .card-number--input {
  width: 100%;
}

.card-exp--inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-exp--input {
  width: 60%;
}

.card-save-input {
  border-radius: 5px;
  height: 40px;
  font-size: 1.5rem;
  color: rgba(54, 56, 77, 0.8);
  font-weight: 600;
  border: 1px solid #cccccc;
}

.save-card--label {
  font-size: 1.6em;
}

.card-buttons {
  width: 80%;
  height: 30px;
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-buttons--choose {
  width: 30%;
  height: 100%;
}

.card-buttons--selected {
  width: 30%;
  height: 100%;
}

.card-buttons--delete {
  width: 30%;
  height: 100%;
}

.card-buttons--selected button {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  background-color: #f0f4f7;
  /*background-color: #17216b;*/
  color: black;
  cursor: pointer;
}

.card-buttons--choose button {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  background-color: #17216b;
  /*background-color: #17216b;*/
  color: #fff;
  cursor: pointer;
}

.card-buttons--delete button {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  background-color: #990000;
  /*background-color: #17216b;*/
  color: #fff;
  cursor: pointer;
}

.delete-icon {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 5px;
}
.delete-icon--svg {
  background-color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
}
/*style={{width: "100%", backgroundColor: '#fff', border: '1px solid #ebedf2', boxShadow: "1px solid #ebedf2" ,borderRadius: '8px'}}*/
/*style={{width: "100%", display:'flex', flexDirection:"row", flexWrap: "wrap"}}*/
/*style={{width: "275px", height: "150px", color:"white", fontWeight: 500, margin: "20px", borderRadius:"8px", backgroundColor: "#17216b", display:'flex', justifyContent:"space-around", flexDirection:"column"}}*/
/*style={{width: "100%", display: "flex", justifyContent:"space-between", alignItems:'center', fontSize: "2rem", padding:"0.5em"}}*/
/*style={{display: "flex", justifyContent: "center", alignItems:"center", fontSize:"2.5rem"}}*/
/*style={{width: "100%", display: "flex", justifyContent:"space-around", fontSize: "1.5rem", padding:"0.5em"}}*/
