.shop-info__container {
  padding: 0 1.5rem;
}

.shop-info__content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 3rem;
  padding: 3rem;
  margin: 2rem 0;
  border: 1px solid #f0f4f7;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 14px 20px rgb(54 56 77 / 15%);
  overflow: hidden;
}

.shop-info__title {
  color: #17216b;
  font-weight: 500;
  font-size: 2.2rem;
  display: block;
}
.shop-info__title span:first-child {
  margin-right: 2rem;
}

.shop-info__status {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 100vw;
  border: 1px solid #17216b;
  font-size: 1.5rem;
  vertical-align: middle;
}

.shop-info__logo {
  flex: 1;
  min-width: 250px;
  margin-bottom: 2rem;
}

.shop-info__summary {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: normal;
  color: rgba(54, 56, 77, 0.8);
}

.shop-info__details {
  flex: 1;
  margin-top: 2rem;
  min-width: 400px;
  list-style-type: none;
}

.shop-info__detail {
  font-size: 1.5rem;
  color: rgba(54, 56, 77, 0.8);
}

.shop-info__detail:not(:first-child) {
  margin-top: 0.5rem;
}

.shop-info__detail b {
  margin-right: 1rem;
  color: #17216b;
}
.shop-info__detail address {
  display: inline-block;
  font-style: normal;
}

.shop-info__link {
  text-decoration: none;
  color: inherit;
}

.shop-info__link:hover {
  color: #17216b;
  text-decoration: underline;
}

.shop-info__map {
  min-width: 350px;
  min-height: 350px;
  flex: 1;
  align-self: stretch;
  z-index: 0;
}
@media screen and (max-width: 450px) {
  .shop-info__logo,
  .shop-info__map {
    min-width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .shop-info__details {
    min-width: 100%;
  }
}
