.shops__card-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  text-decoration: none;
  height: auto;
  position: relative;
  background-color: #fff;
  z-index: 1;
  margin-top: 20px;
}
.shops__card-img {
  width: 100%;
}
.product-card {
  cursor: pointer;
}

.shops__card-img img {
  width: 100%;
  height: 100%;
}
.product-card__content h3 {
  color: #17216b;
  margin: 10px 0;
}
.product-card__content .working-time {
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.isactive__shop {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  margin: 10px 0;
}
.isactive__shop.list {
  justify-content: flex-start;
  width: 100%;
}

.isactive__shop.list .shop-status {
  width: 100%;
  text-align: center;
}

.closed-btn {
  width: 60px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #f14753;
  font-size: 14px;
  font-weight: bold;
}
.shop-status {
  border: 1px solid #17216b;
  padding: 5px 10px;
  border-radius: 10px;
}
.searchform__container {
  padding: 30px;
}
.product-card__content-list {
  padding: 0;
}
.product-card__content-list h3 {
  font-size: 16px;
  color: #17216b;
}
.product-card__content-list p {
  margin: 5px 0;
  font-size: 14px;
}
