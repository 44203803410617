.skeleton {
  animation: skeleton_loading 1s linear infinite alternate;
  -webkit-animation: skeleton_loading 1s linear infinite alternate;
}

// grid
.skeleton_card {
  max-width: 380px;
  padding: 20px;
  flex: 0 0 20%;
  * {
    border-radius: 5px;
  }
  &__img {
    width: 100%;
    height: 200px;
  }

  &__rating {
    width: 50px;
    height: 10px;
    margin: 10px 0;
  }
  &__summary {
    width: 100%;
    height: 15px;
    margin-top: 3px;
  }
  &__summary.last {
    width: 80%;
  }
  &__cost {
    width: 100px;
    height: 25px;
    margin: 10px 0 15px;
  }
  &__btn {
    width: 100%;
    height: 40px;
    border: none;
  }
}

.skeleton_card.shop {
  .skeleton_card__cost {
    display: none;
  }
  .skeleton_card__btn {
    width: 50%;
    height: 30px;
    margin-top: 10px;
    margin-left: 50%;
    border-radius: 20px;
  }
}

.skeleton_card.market {
  .skeleton_card__cost {
    display: none;
  }
  .skeleton_card__btn {
    display: none;
  }
}

.all-products__skeleton {
  flex: 0 0 27rem;
  border: 1px solid #ebedf2;
  box-shadow: 0 14px 20px rgb(54 56 77 / 15%);
  border-radius: 8px;
  margin-bottom: 3rem;
}

// list
.skeleton_card-list {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  position: relative;
  padding: 10px;
  margin-bottom: 3rem;
  * {
    border-radius: 5px;
  }
  &__img {
    flex: 0 0 33%;
    width: 100%;
    height: 21rem;
    overflow: hidden;
  }

  &__content {
    padding: 3rem 0;
    flex: 0 0 35%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  &__summary.last {
    width: 100%;
    height: 15px;
    margin-bottom: auto;
  }
  &__rating {
    width: 60px;
    height: 10px;
    margin-top: auto;
  }
  &__actions {
    padding: 3rem;
    border-left: 1px solid #ebedf2;
    -ms-flex: 0 0 28%;
    flex: 0 0 28%;
    display: flex;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  &__price {
    max-width: 100px;
    height: 23px;
  }
  &__btn {
    width: 100%;
    height: 40px;
  }
}

.skeleton_card-list.all-products__skeleton-list {
  background-color: #fff;
  box-shadow: 0 14px 20px rgb(54 56 77 / 15%);
  border-radius: 8px;
  border: 1px solid #ebedf2;
}

.skeleton_card-list.market {
  .skeleton_card-list__cost {
    display: none;
  }
  .skeleton_card-list__btn {
    display: none;
  }
}

.skeleton_card-list.shop {
  .skeleton_card-list__rating {
    margin-top: 10px;
  }
  .skeleton_card-list__btn {
    display: none;
  }
  .skeleton_card-list__price {
    max-width: 55%;
    border-radius: 20px;
    margin-left: 22.5%;
  }
}

// @media query
@media (max-width: 73.2em) {
  .skeleton_card {
    flex: 0 0 33.33%;
  }
  .all-products__skeleton {
    flex: 0 0 32%;
  }
}

@media (max-width: 48em) {
  .skeleton_card {
    flex: 0 0 50%;
  }
  .all-products__skeleton {
    flex: 0 0 49%;
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 450px) {
  .skeleton_card {
    padding: 10px;
  }
  .skeleton_card__img {
    height: 150px;
  }
}

@keyframes skeleton_loading {
  0% {
    background-color: hsl(200, 19%, 70%);
  }

  100% {
    background-color: hsl(205, 22%, 85%);
  }
}
