.myaddress__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-sizing: content-box; */
}

.myaddress__container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  /* position: relative; */
  padding: 20px;
}

.myaddress__container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myaddress__title {
}

.myaddress__close {
}

.myaddress__close-btn {
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
}

.myaddress__close-btn svg {
}

.myaddress__map {
  flex: 1;
  position: relative;
  /* z-index: 10000000; */
  height: 100%;
  width: 100%;
}
.myaddress__map > svg {
  color: rgb(46, 46, 255);
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
}
.myaddress__map .leaflet-container {
  height: 100%;
}

.myaddress__info {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  margin-bottom: 10px;
}

.myaddress__info .find__me-btn {
  position: absolute;
  left: 0;
  height: 90%;
  background: #17216b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  padding: 5px 20px;
}

.myaddress__info .myaddress__info-location {
  position: relative;
  height: 50px;
  background: #f6f6f6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 100px;
  overflow-x: hidden;
  width: 100%;
}

.myaddress__info-location .myaddress__info-location-info {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  overflow-x: hidden;
  max-width: 100%;
  position: relative;
}

.myaddress__info .location__accept {
  height: 50px;
  background: #17216b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
}

.myaddress__detail {
  display: flex;
  column-gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.myaddress__detail .myaddress__detail-input {
  width: 33.33%;
}

.myaddress__detail input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0 5px;
  font-weight: 600;
  background: #f6f6f6;
}

.myaddress__detail input:focus {
  border: 2px solid #17216b;
  color: #17216b;
}
.myaddress__detail input:focus::placeholder {
  color: #17216b;
}

@media screen and (max-width: 640px) {
  .myaddress__container {
    width: 95%;
    height: 550px;
    overflow-y: scroll;
  }
  .myaddress__info {
    flex-direction: column;
    row-gap: 3px;
  }

  .myaddress__map {
    height: 87% !important;
  }
  /* .myaddress__map .leaflet-container {
    height: 87% !important;
  } */
  .myaddress__detail {
    flex-direction: column;
    row-gap: 5px;
  }
  .myaddress__detail .myaddress__detail-input {
    width: 100%;
  }
}

@media screen and (min-width: 640px) and (max-width: 960px) {
  .myaddress__container {
    width: 80%;
  }
}
