.selected-shop {
  font-size: 1.6rem;
  color: rgba(54, 56, 77, 0.8);
  font-weight: 500;
}
.cancel-red {
  color: red;
  font-size: 12px;
  cursor: pointer;
  margin-top: 3px;
}

.shop-name {
  font-weight: bold;
}
