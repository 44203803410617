.my_form__input {
    padding-left: 6.1rem;
}

.static-value {
    position: absolute;
    left: 5px;
    padding-left: 15px;
    font-size: 1.8em !important;
    font-weight: bold;
    color: #444;
    padding-top: 1.8rem;
}

.hide-static-value {
    display: none;
}

.not-valid {
    color: red;
}
